<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="videoData === undefined">
      <h4 class="alert-heading">Error fetching video data</h4>
      <div class="alert-body">
        No Video found with this video id. Check
        <b-link class="alert-link" :to="{ name: 'apps-videos-list' }">
          Videos List
        </b-link>
        for other videos.
      </div>
    </b-alert>

    <template v-if="videoData">
    <b-row>
      <b-col cols="8">
        <b-card>
          <video-player  class="video-player-box"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true">
          </video-player>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <div class="d-flex flex-column">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ videoData.title }}
                </h4>
              </div>
              <div class="d-flex flex-wrap">
                <b-badge
                  class="mr-1"
                  pill
                  :variant="`light-success`"
                  v-for="category in videoData.categories"
                  :key="category.id"
                >
                  {{ category.name }}
                </b-badge>
              </div>
              <div class="d-flex flex-wrap mt-1">
                <b-button variant="outline-warning" @click="editVideo()"
                  >Edit</b-button
                >
                <b-button
                  class="ml-1"
                  variant="outline-danger"
                  @click="deleteVideo()"
                  >Delete</b-button
                >
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
        <b-card>
          {{ videoData.description }}
        </b-card>

      <!-- Edit Video Modal -->
      <b-modal
        v-model="editVideoModal"
        hide-footer
        centered
        size="lg"
        title="Edit Article"
      >
        <b-form-group label="Enter the Article's Title" label-for="enterTitle">
          <b-form-input
            id="enterTitle"
            placeholder="Enter the Article's Title"
            v-model="editVideoTitle"
          />
        </b-form-group>

        <b-form-group
          label="Select Article's Categories"
          label-for="selectCategories"
        >
          <div class="demo-inline-spacing">
            <b-form-checkbox
              v-for="category in categories"
              :key="category.value"
              v-model="editVideoCategories"
              :checked="editVideoCategories.includes(category.value)"
              :value="category.value"
            >
              {{ category.text }}
            </b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group label="Article Content" label-for="articleContent">
          <b-form-textarea
            v-model="editVideoDescription"
            placeholder="Enter the content of the article"
            rows="4"
            :state="editVideoDescription.length <= maxChar"
            class="char-textarea"
            :class="editVideoDescription.length >= maxChar ? 'text-danger' : ''"
          />
          <small
            class="textarea-counter-value float-right"
            :class="editVideoDescription.length >= maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ editVideoDescription.length }}</span> /
            {{ maxChar }}
          </small>
        </b-form-group>

        <b-form-group label="Select Video's File" label-for="selectFile">
          <b-form-file
            v-model="editVideoFile"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".mp4"
          />

          <b-card-text class="my-1">
            Selected file: <strong>{{ editVideoFile ? editVideoFile.name : "" }}</strong>
          </b-card-text>
        </b-form-group>

        <b-button variant="outline-primary" @click="updateVideo()">
          Submit
        </b-button>
      </b-modal>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted, onUpdated } from "@vue/composition-api";
import {
  BCard,
  BAvatar,
  BRow,
  BCol,
  BButton,
  BAlert,
  BBadge,
  BLink,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BCardText,
} from "bootstrap-vue";
import videoStore from "./videoStore";
import useVideoList from "./useVideosList";
import { avatarText } from "@core/utils/filter";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// require styles
import 'video.js/dist/video-js.css'
 
import { videoPlayer } from 'vue-video-player'

export default {
  components: {
    BCard,
    BAvatar,
    BButton,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BModal,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
    BCardText,

    videoPlayer,
  },
  setup() {
    // Use toast
    const toast = useToast();

    const VIDEO_APP_STORE_MODULE_NAME = "app-videos";

    const maxChar = ref(200);

    const categories = [];

    const fetchCategories = () => {
      store
        .dispatch("app-categories/fetchAllCategories")
        .then((response) => {
          response.data.forEach((category) => {
            categories.push({ value: category.id, text: category.name });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchCategories();

    // Register module
    if (!store.hasModule(VIDEO_APP_STORE_MODULE_NAME))
      store.registerModule(VIDEO_APP_STORE_MODULE_NAME, videoStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VIDEO_APP_STORE_MODULE_NAME))
        store.unregisterModule(VIDEO_APP_STORE_MODULE_NAME);
    });

    onUpdated(() => {
      playerOptions.sources[0].src = videoData.value.video_url
    })

    const playerOptions = {
      // videojs options
      muted: false,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      width: '750',
      sources: [{
        type: "video/mp4",
        src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
      }],
    }

    store
      .dispatch("app-videos/fetchVideo", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        videoData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          videoData.value = undefined;
        }
      });

    const {
      editVideoId,
      editVideoTitle,
      editVideoCategories,
      editVideoDescription,
      editVideoFile,
      editVideoModal,
      updateVideo,
      videoData,
      deleteVideo,
    } = useVideoList();

    const editVideo = () => {
      videoData.value.categories.forEach((category) => {
        editVideoCategories.value.push(category.id);
      });
      editVideoId.value = videoData.value.id;
      editVideoTitle.value = videoData.value.title;
      editVideoDescription.value = videoData.value.description != null ? videoData.value.description : '';

      editVideoModal.value = true;
    };

    return {
      videoData,
      avatarText,
      editVideoModal,
      maxChar,

      categories,

      playerOptions,

      videoData,
      editVideo,

      editVideoId,
      editVideoTitle,
      editVideoCategories,
      editVideoDescription,
      editVideoFile,
      updateVideo,

      deleteVideo,
    };
  },
};
</script>

<style></style>
