import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useArticlesList() {
  // Use toast
  const toast = useToast();

  const refVideosListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "title", sortable: true },
    { key: "categories" },
    { key: "uploaded-on", sortable: true },
    { key: "actions" },
  ];
  const per_page = ref(10);
  const totalVideos = ref(0);
  const current_page = ref(1);
  const per_pageOptions = [10, 30, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  // Add video variables
  const addVideoModal = ref(false);
  const addVideoTitle = ref("");
  const addVideoCategories = ref([]);
  const addVideoDescription = ref("");
  const addVideoFile = ref(null);

  // Single Video Data
  const videoData = ref(null);

  // Edit video variables
  const editVideoModal = ref(false);
  const editVideoId = ref("");
  const editVideoTitle = ref("");
  const editVideoCategories = ref([]);
  const editVideoDescription = ref("");
  const editVideoFile = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refVideosListTable.value
      ? refVideosListTable.value.localItems.length
      : 0;
    return {
      from:
        per_page.value * (current_page.value - 1) + (localItemsCount ? 1 : 0),
      to: per_page.value * (current_page.value - 1) + localItemsCount,
      of: totalVideos.value,
    };
  });

  const refetchData = () => {
    refVideosListTable.value.refresh();
  };

  watch([current_page, per_page, searchQuery], () => {
    refetchData();
  });

  const fetchVideos = (ctx, callback) => {
    store
      .dispatch("app-videos/fetchVideos", {
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then((response) => {
        const { data: videos, total } = response.data;

        callback(videos);
        totalVideos.value = total;
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching videos list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const storeVideo = (ctx, callback) => {
    const formData = new FormData();
    formData.append("title", addVideoTitle.value);
    formData.append("categories", addVideoCategories.value);
    formData.append("description", addVideoDescription.value);
    formData.append("video", addVideoFile.value);
    store
      .dispatch("app-videos/addVideo", formData)
      .then(() => {
        refetchData();
        addVideoModal.value = false;
        addVideoTitle.value = "";
        addVideoCategories.value = [];
        addVideoDescription.value = "";
        addVideoFile.value = null;
        toast({
          component: ToastificationContent,
          props: {
            title: "Video uploaded.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while adding the video.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const updateVideo = () => {
    const formData = new FormData();
    formData.append("title", editVideoTitle.value);
    formData.append("categories", editVideoCategories.value);
    formData.append("description", editVideoDescription.value);
    formData.append("video", editVideoFile.value);

    store
      .dispatch("app-videos/updateVideo", {
        id: editVideoId.value,
        data: formData,
      })
      .then((response) => {
        videoData.value = response.data.data;
        toast({
          component: ToastificationContent,
          props: {
            title: "Video updated.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        editVideoModal.value = false;
        editVideoId.value = "";
        editVideoTitle.value = "";
        editVideoCategories.value = [];
        editVideoDescription.value = "";
        editVideoFile.value = null;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while updating the video.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const deleteVideo = (ctx, callback) => {
    store
      .dispatch("app-videos/deleteVideo", { id: videoData.value.id })
      .then(() => {
        router.push({ name: "apps-videos-list" });
        toast({
          component: ToastificationContent,
          props: {
            title: "Video deleted.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error deleting video.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchVideos,
    storeVideo,
    updateVideo,
    deleteVideo,
    tableColumns,
    per_page,
    current_page,
    totalVideos,
    dataMeta,
    per_pageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refVideosListTable,
    refetchData,

    addVideoModal,
    addVideoTitle,
    addVideoCategories,
    addVideoDescription,
    addVideoFile,

    videoData,

    editVideoModal,
    editVideoId,
    editVideoTitle,
    editVideoCategories,
    editVideoDescription,
    editVideoFile,

    deleteVideo
  };
}
