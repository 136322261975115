import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVideos(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("admin/videos", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchVideo(ctx, video) {
      return new Promise((resolve, reject) => {
        axios
          .get("admin/videos/" + video.id)
          .then((response) => resolve(response))
          .catch((error) => reject(error.response));
      });
    },
    addVideo(ctx, video) {
      return new Promise((resolve, reject) => {
        axios
          .post("admin/videos", video)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateVideo(ctx, video) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/videos/${video.id}/update`, video.data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteVideo(ctx, video) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/videos/${video.id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
